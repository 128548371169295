<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.btn_shadow_half {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}
td {
  border-bottom: 1px solid #dbdbdb;
}

.result_title {
  max-height: 10px;
}

/* 追加 */
.v-input {
  font-size: 14px;
}
.select {
  margin-left: 30px;
  margin-bottom: 7px;
  height: 10px;
}

.DLbtn {
  width: 4rem;
}
</style>

<template>
  <!-- Main Campaign Management Section -->
  <div>
    <!-- Campaign Search Card -->
    <v-card width="60vw" class="ma-10 card_shadow" v-show="!isShowComponent">
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-container class="ml-5 pt-5">
          <v-row class="headline ml-n8 mt-n5">
            <v-col class="active_text">
              <p>{{ $t('home.campaigns') }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-10 mt-10 mb-n8 text-body-2">
            <p v-html="$t('home.campaignSearchScreen')"></p>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                class="v-input"
                v-model="filterExam"
                append-icon="mdi-magnify"
                :label="$t('home.searchQueryPlaceholder')"
                single-lin5
                hide-details
                @keypress.enter="searchCampaign"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                class="ma-2 mb-5 btn_shadow_half"
                color="#1b2643"
                dark
                @click="searchCampaign"
              >
                {{ $t('home.search') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mr-10 mb-4 text-body-2">
            <v-btn @click="isShowFilter = !isShowFilter" text
              >{{ $t('home.option.serchOption') }}
              <v-icon v-if="!isShowFilter">mdi-menu-right</v-icon>
              <v-icon v-else>mdi-menu-down</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="isShowFilter">
            <v-col class="d-flex" cols="8">
              <v-select
                v-model="searchFor"
                :items="[
                  {
                    text: $t(
                      'home.stringSearchOptions.searchByCampaignCodeOrName'
                    ),
                    value: 'searchByCampaignCodeOrName',
                  },
                  {
                    text: $t('home.stringSearchOptions.searchByCampaignCode'),
                    value: 'searchByCampaignCode',
                  },
                  {
                    text: $t('home.stringSearchOptions.searchByCampaignName'),
                    value: 'searchByCampaignName',
                  },
                ]"
                :label="$t('home.option.searchStringTarget')"
                dense
                class="select"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="isShowFilter">
            <v-col class="d-flex" cols="4">
              <v-select
                v-model="filterByPublicationPeriod"
                :items="[
                  {
                    text: $t('home.publicationPeriodFilterOption.noFilter'),
                    value: 'noFilter',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.beforePublicationPeriod'
                    ),
                    value: 'beforePublicationPeriod',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.duringPublicationPeriod'
                    ),
                    value: 'duringPublicationPeriod',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.afterPublicationPeriod'
                    ),
                    value: 'afterPublicationPeriod',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.specifyPublicationPeriod'
                    ),
                    value: 'specifyPublicationPeriod',
                  },
                ]"
                :label="$t('home.option.publicationPeriodFilter')"
                dense
                @change=";(filterByOpenTime = 0), (filterByClosedTime = 0)"
                class="select"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="ml-10"
            v-if="
              isShowFilter &&
              filterByPublicationPeriod ===
                $t(
                  'home.publicationPeriodFilterOption.specifyPublicationPeriod'
                )
            "
          >
            <v-col cols="5">
              <set-schedule
                :cardTitle="String($t('home.timeSetting.startDate'))"
                @changeDateTime="filterByOpenTime = $event"
              />
            </v-col>
            <v-col cols="5">
              <set-schedule
                :cardTitle="String($t('home.timeSetting.endDate'))"
                @changeDateTime="filterByClosedTime = $event"
              />
            </v-col>
          </v-row>
          <v-row class="mb-4" v-if="isShowFilter">
            <v-col class="d-flex" cols="4">
              <v-select
                v-model="filterByExamPeriod"
                :items="[
                  {
                    text: $t('home.examinationPeriodFilterOption.noFilter'),
                    value: 'noFilter',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.beforeInterviewPeriod'
                    ),
                    value: 'beforeInterviewPeriod',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.duringInterviewPeriod'
                    ),
                    value: 'duringInterviewPeriod',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.afterInterviewPeriod'
                    ),
                    value: 'afterInterviewPeriod',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.specifyInterviewPeriod'
                    ),
                    value: 'specifyInterviewPeriod',
                  },
                ]"
                :label="$t('home.option.examinationPeriodFilter')"
                dense
                @change=";(filterByStartTime = 0), (filterByEndTime = 0)"
                class="select"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="ml-10"
            v-if="
              isShowFilter &&
              filterByExamPeriod ===
                $t('home.examinationPeriodFilterOption.specifyInterviewPeriod')
            "
          >
            <v-col cols="5">
              <set-schedule
                :cardTitle="String($t('home.timeSetting.startDate'))"
                @changeDateTime="filterByStartTime = $event"
              />
            </v-col>
            <v-col cols="5">
              <set-schedule
                :cardTitle="String($t('home.timeSetting.endDate'))"
                @changeDateTime="filterByEndTime = $event"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <!-- Campaign Results Table -->
    <v-card
      max-width="80vw"
      class="ma-10 card_shadow"
      v-show="!isShowComponent"
    >
      <template v-if="isLoadingCampaigns">
        <v-progress-linear indeterminate></v-progress-linear>
        <p class="w-full text-center primary--text text-caption">
          {{
            isLoadingCampaigns
              ? $t('home.progress.loadingCampaigns')
              : $t('home.progress.loadingCouponCounts')
          }}
        </p>
      </template>
      <v-form ref="form">
        <v-container class="pt-5">
          <v-row class="ml-5 mr-10 mt-3 mb-n8 text-body-2">
            <p>
              {{
                $t('home.displayCampaign', {
                  totalCampaigns: campaignNum,
                  displayedCampaigns: isShowCampaignNum,
                })
              }}
            </p>
          </v-row>
          <v-row
            v-if="searched && filterExamString"
            class="ml-10 mr-10 text-body-2"
          >
            {{ $t('home.searchquery') }}
            {{
              $t('home.filterCampaign', {
                filterCampaigns: filterExamString,
                searchByCampaignCodeOrName: $t(
                  `home.stringSearchOptions.${searchFor}`
                ),
              })
            }}
          </v-row>
          <v-row
            v-if="filterByPublicationPeriodString || filterByExamPeriodString"
            class="ml-10 mr-10 text-body-2"
          >
            {{ $t('home.filter') }}
            {{
              filterByPublicationPeriodString &&
              $t(
                `home.publicationPeriodFilterOption.${filterByPublicationPeriodString}`
              )
            }}
            {{
              filterByPublicationPeriodString && filterByExamPeriodString
                ? '&'
                : ''
            }}
            {{
              filterByExamPeriodString &&
              $t(
                `home.examinationPeriodFilterOption.${filterByExamPeriodString}`
              )
            }}
          </v-row>
          <v-row>
            <v-col cols="12" class="px-0">
              <v-data-table
                :headers="headers"
                :items="campaignData"
                item-key="campaignCode"
                :items-per-page="-1"
                :disable-pagination="true"
                :hide-default-footer="true"
                :options="options"
                :group-by="groupBy"
              >
                <template
                  v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
                >
                  <template
                    v-if="
                      group !== '' && group !== 'null' && group !== '\u{10FFFF}'
                    "
                  >
                    <td colspan="6" class="text-start font-weight-bold">
                      <v-btn icon small @click="toggle()">
                        <v-icon>{{
                          isOpen ? 'mdi-menu-down' : 'mdi-menu-right'
                        }}</v-icon>
                      </v-btn>
                      <v-icon left>{{
                        isOpen
                          ? 'mdi-package-variant'
                          : 'mdi-package-variant-closed'
                      }}</v-icon
                      >{{ group
                      }}<v-chip small class="ml-2">
                        {{ getShowingCampaignCount(items) }}</v-chip
                      >
                    </td>

                    <td>
                      <router-link
                        :to="`/dashboard/package/edit/${items[0].packageId}`"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </router-link>
                    </td>
                    <td>
                      <v-icon
                        small
                        class="mr-2"
                        @click="openPackageCouponList(items[0].packageId)"
                        >mdi-list-box-outline</v-icon
                      >
                    </td>
                    <td>
                      <router-link
                        :to="`/dashboard/package-results/${
                          packageData[items[0].packageId].code
                        }`"
                      >
                        <v-icon small>mdi-file-search-outline</v-icon>
                      </router-link>
                    </td>

                    <td></td>
                  </template>
                  <template v-else>
                    <td colspan="10" class="text-start font-weight-bold">
                      {{ $t('home.individualCampaign')
                      }}<v-chip small class="ml-2">
                        {{ getShowingCampaignCount(items) }}</v-chip
                      >
                    </td>
                  </template>
                </template>
                <template v-slot:[`header.campaignCode`]="{ header }">
                  <th class="d-flex text-start" v-html="header.text"></th>
                </template>
                <template v-slot:[`header.couponCounts`]="{ header }">
                  <th class="d-flex text-start" v-html="header.text"></th>
                </template>
                <template v-slot:item="{ item }">
                  <tr v-show="item.isShow">
                    <td>{{ item.packageId ? item.packageIndex + 1 : '' }}</td>
                    <td :style="{ color: item.isDynamic ? '#e2d06e' : '' }">
                      <b>{{ item.campaignName }}</b
                      ><br />
                      ({{ item.campaignCode }})
                    </td>
                    <td :style="{ color: item.isDynamic ? '#e2d06e' : '' }">
                      {{ UnixTimeToString(item.openTime) }} ~
                      {{ UnixTimeToString(item.closedTime) }}
                    </td>
                    <td :style="{ color: item.isDynamic ? '#e2d06e' : '' }">
                      {{ UnixTimeToString(item.startTime) }} ~
                      {{ UnixTimeToString(item.endTime) }}
                    </td>
                    <td :style="{ color: item.isDynamic ? '#e2d06e' : '' }">
                      {{ item.couponNum }}&nbsp;/&nbsp;{{
                        item.confirmedCouponCount
                      }}
                    </td>
                    <td :style="{ color: item.isDynamic ? '#e2d06e' : '' }">
                      <span v-if="item.isUnlimited">{{
                        $t('campaignSetting.interviewCountOption.unlimited')
                      }}</span>
                      <span v-else
                        >{{ item.limits }}
                        {{ $t('campaignSetting.time') }}</span
                      >
                    </td>
                    <td>
                      <v-icon
                        v-show="!item.packageId"
                        small
                        class="mr-2"
                        @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </td>
                    <td>
                      <v-icon
                        v-show="!item.packageId"
                        small
                        class="mr-2"
                        @click="openCampaignCouponList(item.campaignId)"
                        >mdi-list-box-outline</v-icon
                      >
                    </td>
                    <td>
                      <router-link
                        :to="`/dashboard/campaign-results/${item.campaignCode}`"
                      >
                        <v-icon small>mdi-file-search-outline</v-icon>
                      </router-link>
                    </td>
                    <td>
                      <v-btn
                        :disabled="item.isProcessing"
                        class="btn_shadow_half"
                        color="#1b2643"
                        dark
                        @click="downloadBtnAction(item.campaignId)"
                        v-show="!item.isDownloading"
                        small
                      >
                        <v-icon small left color="white"
                          >mdi-folder-zip-outline</v-icon
                        >
                        DL
                      </v-btn>
                      <v-btn
                        disabled
                        class="DLbtn"
                        color="white"
                        v-show="item.isDownloading"
                        small
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          size="18"
                        ></v-progress-circular>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-form>
        <v-container>
          <snack-bar
            :snackbarAction="snackbarActionBtn"
            :snackbarText="snackbarTextField"
            :snackbarColor="snackbarColorField"
            @closeSnackbar="closeSnackbar"
          />
        </v-container>
      </v-form>
    </v-card>

    <set-exam
      v-show="isShowComponent"
      :isModify="true"
      :campaignId="editedItem.campaignId"
      :campaignCode="editedItem.campaignCode"
      :campaignName="editedItem.campaignName"
      :openTime="editedItem.openTime"
      :closedTime="editedItem.closedTime"
      :startTime="editedItem.startTime"
      :endTime="editedItem.endTime"
      :isUnlimited="editedItem.isUnlimited"
      :limits="String(editedItem.limits)"
      :isDynamicCampaign="editedItem.isDynamic"
      :couponNum="editedItem.couponNum"
      :scenarioDocId="editedItem.scenarioDocId"
      @closeComponent="resetComponent"
      @deleteItem="deleteItem"
    />

    <!-- Coupon Management Dialog -->
    <coupon-list-dialog
      v-if="isCouponDialogVisible"
      :isVisible.sync="isCouponDialogVisible"
      :type="couponDialogCollection"
      :id="couponDialogId"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
  getCountFromServer,
  QueryDocumentSnapshot,
  DocumentData,
  collectionGroup,
  QuerySnapshot,
} from 'firebase/firestore'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import store from '@/store'
import SetExam from '@/components/Dashboard/PageViewItems/SetExamCardComponent.vue'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import SetSchedule from '@/components/Dashboard/PageViewItems/SetScheduleComponent.vue'
import moment from 'moment-timezone'
import type { Campaign } from '@/resources/campaign'
import type { TableHeader } from '@/resources/table'
import type { Result } from '@/resources/result'
import {
  createCsvContent,
  downloadCsv,
  downloadNoResultCsv,
  getCampaignListByCustomerId,
} from '@/utils'
import CouponListDialog from '@/components/Dashboard/Dialogs/CouponListDialog.vue'
import { getJstTimestamp } from '@/components/utils/dateUtils'
import { getPackageListByCustomerId } from '@/utils/packageUtils'

type BaseCampaign = Pick<
  Campaign,
  | 'campaignCode'
  | 'customerCode'
  | 'customerId'
  | 'category'
  | 'isShow'
  | 'isUnlimited'
  | 'limits'
  | 'scenarioDocId'
  | 'systemInfo'
>

type CampaignType = BaseCampaign & {
  campaignId: string
  campaignName: string
  openTime: number
  closedTime: number
  startTime: number
  endTime: number
  couponNum: number
  confirmedCouponCount: number
  isDownloading: boolean
  isProcessing: boolean
  isDynamic?: boolean
  packageCode: string
  packageId: string
  packageIndex: number
}

type PackageType = {
  [packageId: string]: {
    code: string
    name: string
    campaignIds: string[]
  }
}

type ResultType = {
  userInfo: Result['userInfo'] & {
    campaignName?: string
  }
  dialogStatus: Result['dialogStatus']
  fastResult: Result['fastResult']
  systemInfo: Result['systemInfo']
}

/**
 * Main dashboard component for campaign management
 * Handles campaign listing, filtering, and coupon management
 */
export default defineComponent({
  name: 'HomeView',

  components: {
    SetExam,
    SnackBar,
    SetSchedule,
    CouponListDialog,
  },

  // Component data properties organized by feature
  data() {
    return {
      // Campaign Search & Filter Properties
      filterExam: '',
      filterExamString: '',
      filterByPeriod: 'all',
      filterByPublicationPeriod: 'all',
      filterByExamPeriod: 'all',
      searchFor: 'all',
      isShowFilter: false,
      filterByPublicationPeriodString: '',
      filterByExamPeriodString: '',
      searchForString: '',
      searched: false,
      filterByOpenTime: 0,
      filterByClosedTime: 0,
      filterByStartTime: 0,
      filterByEndTime: 0,

      // Campaign Data Properties
      campaignInfo: false,
      campaignNum: 0,
      isShowCampaignNum: 0,
      isShowComponent: false,
      isLoadingCampaigns: false,
      campaignData: [] as CampaignType[],
      packageData: {} as PackageType,

      // Campaign Edit Properties
      editedIndex: -1,
      editedItem: {} as CampaignType,

      // Notification Properties
      snackbarActionBtn: false,
      snackbarTextField: '',
      snackbarColorField: '',

      // Results Properties
      resultList: [] as ResultType[],
      headers: [] as TableHeader[],
      options: {
        sortBy: ['packageIndex'],
        sortDesc: [false],
      },
      groupBy: 'packageCode',

      // Coupon Dialog Properties
      isCouponDialogVisible: false,
      couponDialogCollection: null as null | 'campaigns' | 'packages',
      couponDialogId: '' as null | string,
    }
  },

  props: {
    snackbarAction: { type: Boolean, default: false },
    snackbarText: { type: String, default: '' },
    snackbarColor: { type: String, default: '' },
  },

  // 画面ロード時の認証とデータ取得
  async created() {
    this.initialize()
    this.updateHeaders()
  },

  async mounted() {
    await this.readData()
  },

  watch: {
    '$i18n.locale': function () {
      this.updateHeaders()
    },
    isCouponDialogVisible(val: boolean) {
      if (!val) {
        this.resetCouponDialogState()
      }
    },
  },
  methods: {
    /**
     * Component Lifecycle Methods
     */
    initialize() {
      // propsの置き換え
      this.snackbarActionBtn = this.snackbarAction
      this.snackbarTextField = this.snackbarText
      this.snackbarColorField = this.snackbarColor
    },

    async resetComponent(emitedText: string) {
      this.isShowComponent = false
      this.snackbarActionBtn = true
      this.snackbarTextField = emitedText
      this.snackbarColorField = 'pink'
      await this.readData()
    },

    /**
     * Campaign Management Methods
     */
    editItem(item: CampaignType) {
      this.editedIndex = this.campaignData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.isShowComponent = true
      // 以下画面遷移時のフィルタに関する不具合修正のための初期化
      // 急ピッチでの修正なのでもう少し良い形にしたい
      // Reset filter state when transitioning screens
      this.filterExamString = ''
      this.searchFor = ''
      this.searchForString = ''
      this.filterByExamPeriod = ''
      this.filterByExamPeriodString = ''
      this.filterByPublicationPeriod = ''
      this.filterByPublicationPeriodString = ''
    },
    // テスト削除ボタンコンポーネントで「削除」ボタンを押した際に呼び出される関数
    deleteItem() {
      this.$router.go(0)
      this.campaignData.splice(this.editedIndex, 1)
      this.editedIndex = -1
      this.editedItem = {
        customerCode: '',
        customerId: '',
        campaignId: '',
        campaignCode: '',
        campaignName: '',
        category: '',
        openTime: 0,
        closedTime: 0,
        startTime: 0,
        endTime: 0,
        couponNum: 0,
        confirmedCouponCount: 0,
        isShow: true,
        isUnlimited: false,
        isDownloading: false,
        isProcessing: false,
        limits: 0,
        scenarioDocId: '',
        systemInfo: {
          Bacon: {
            systemVersion: '',
          },
        },
        packageCode: '',
        packageId: '',
        packageIndex: 0,
      }
      this.isShowComponent = false
      this.snackbarActionBtn = true
      this.snackbarTextField = '削除しました'
      this.snackbarColorField = 'pink'
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.campaignData[this.editedIndex], this.editedItem)
      } else {
        this.campaignData.push(this.editedItem)
      }
    },

    /**
     * Campaign Search and Filter Methods
     */
    searchCampaign() {
      this.isShowCampaignNum = 0 //検索の結果表示されるキャンペーンの数を初期化
      this.searched = true //検索を実行したか否か
      this.filterExamString = this.filterExam //検索語を格納（即時に反映されるのを防ぐために別の変数を使用）
      for (let i = 0; i < this.campaignData.length; i++) {
        var campaign = '' //文字列検索の対象となる文字列を格納するための変数
        this.campaignData[i]['isShow'] = true
        // 文字列検索

        if (this.searchFor === 'searchByCampaignCode') {
          campaign = this.campaignData[i]['campaignCode']
          this.searchForString = this.$t('home.campaignCode') as string
        } else if (this.searchFor === 'searchByCampaignName') {
          campaign = this.campaignData[i]['campaignName']
          this.searchForString = this.$t('home.campaignName') as string
        } else {
          campaign =
            this.campaignData[i]['campaignName'] +
            this.campaignData[i]['campaignCode']
          this.searchForString = this.$t('home.campaignCodeOrName') as string
        }
        if (campaign.indexOf(this.filterExamString) === -1) {
          this.campaignData[i]['isShow'] = false
        }

        // 現在時刻の取得
        var presentTime = new Date(moment().tz('Asia/Tokyo').format()).getTime()

        // 公開期間フィルタ
        this.filterByPublicationPeriodString = this.filterByPublicationPeriod

        if (this.filterByPublicationPeriod === 'beforePublicationPeriod') {
          if (presentTime > this.campaignData[i]['openTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'duringPublicationPeriod'
        ) {
          if (
            presentTime < this.campaignData[i]['openTime'] ||
            presentTime > this.campaignData[i]['closedTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'afterPublicationPeriod'
        ) {
          if (presentTime < this.campaignData[i]['closedTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'specifyPublicationPeriod' &&
          (this.filterByOpenTime !== 0 || this.filterByClosedTime !== 0)
        ) {
          if (
            this.filterByClosedTime < this.campaignData[i]['openTime'] ||
            this.filterByOpenTime > this.campaignData[i]['closedTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
          this.filterByPublicationPeriodString = `${this.$t(
            'home.publicationPeriodFilterOption.specifyPublicationPeriod'
          )}（${this.UnixTimeToString(
            this.filterByOpenTime
          )}) 〜 (${this.UnixTimeToString(this.filterByClosedTime)})`
        } else {
          this.filterByPublicationPeriodString = ''
        }

        // 受験期間フィルタ
        this.filterByExamPeriodString = this.filterByExamPeriod
        if (this.filterByExamPeriod === 'beforeInterviewPeriod') {
          if (presentTime > this.campaignData[i]['startTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (this.filterByExamPeriod === 'duringInterviewPeriod') {
          if (
            presentTime < this.campaignData[i]['startTime'] ||
            presentTime > this.campaignData[i]['endTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (this.filterByExamPeriod === 'afterInterviewPeriod') {
          if (presentTime < this.campaignData[i]['endTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByExamPeriod === 'specifyInterviewPeriod' &&
          (this.filterByStartTime !== 0 || this.filterByEndTime !== 0)
        ) {
          if (
            this.filterByEndTime < this.campaignData[i]['startTime'] ||
            this.filterByStartTime > this.campaignData[i]['endTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
          this.filterByExamPeriodString = `${this.$t(
            'home.examinationPeriodFilterOption.specifyInterviewPeriod'
          )} (${this.UnixTimeToString(
            this.filterByStartTime
          )}) 〜 (${this.UnixTimeToString(this.filterByEndTime)})`
        } else {
          this.filterByExamPeriodString = ''
        }

        // 表示数のカウント
        if (this.campaignData[i]['isShow']) {
          this.isShowCampaignNum++
        }
      }
    },

    /**
     * Data Loading and Management Methods
     */
    async readData() {
      this.campaignData = []
      this.campaignNum = 0
      this.isShowCampaignNum = 0
      this.isLoadingCampaigns = true
      await this.loadCampaignList()
      this.isLoadingCampaigns = false
    },

    async loadCampaignList() {
      const customerId = store.state.customerId
      if (!customerId) {
        return
      }

      const campaignsSnapshot = await getCampaignListByCustomerId(customerId)
      const packagesSnapshot = await getPackageListByCustomerId(customerId)

      await this.mapPackages(packagesSnapshot)
      await this.fixCampaignlessPackages()
      await this.mapCampaigns(campaignsSnapshot)
      await this.loadConfirmedCouponCount()
    },

    async mapPackages(packagesQuerySnapshot: QuerySnapshot<DocumentData>) {
      packagesQuerySnapshot.docs.forEach((packageDoc) => {
        const data = packageDoc.data()
        this.packageData[packageDoc.id] = {
          code: data.code,
          name: data.name,
          campaignIds: data.campaignIds,
        }
      })
    },

    async mapCampaigns(campaignsQuerySnapshot: QuerySnapshot<DocumentData>) {
      for (const document of campaignsQuerySnapshot.docs) {
        const campaignId = document.id
        const campaign = document.data()

        if (campaign.isDeleted) {
          continue
        }

        const packageId = campaign.packageId
        const packageData = this.packageData[packageId]
        const packageCode = this.getPackageCode(packageData)
        const packageIndex = this.getPackageIndex(campaignId, packageData)

        const couponCount = await this.getOrSetCouponCount(document)

        this.campaignNum++
        this.isShowCampaignNum++

        this.campaignData.push({
          customerId: campaign.customerId,
          customerCode: campaign.customerCode,
          campaignId: campaignId,
          campaignCode: campaign.campaignCode,
          campaignName: campaign.name,
          category: campaign.category,
          openTime: this.Utc2UnixTime(campaign.openAt),
          closedTime: this.Utc2UnixTime(campaign.closedAt),
          startTime: this.Utc2UnixTime(campaign.startAt),
          endTime: this.Utc2UnixTime(campaign.endAt),
          couponNum: couponCount,
          confirmedCouponCount: 0,
          isShow: true,
          isUnlimited: campaign.isUnlimited,
          limits: campaign.limits,
          systemInfo: campaign.systemInfo,
          isDownloading: false,
          isProcessing: false,
          scenarioDocId: campaign.scenarioDocId,
          isDynamic: campaign.isDynamic,
          packageCode: packageCode,
          packageId: packageId || '',
          packageIndex: packageIndex,
        })
      }
    },

    async fixCampaignlessPackages() {
      Object.entries(this.packageData).forEach(([packageId, packageData]) => {
        if (packageData.campaignIds.length !== 0) {
          return
        }

        this.campaignData.push({
          packageCode: this.getPackageCode(packageData),
          packageId: packageId,
          packageIndex: 0,
          campaignCode: '',
          campaignName: '',
          category: '',
          openTime: 0,
          closedTime: 0,
          startTime: 0,
          endTime: 0,
          couponNum: 0,
          confirmedCouponCount: 0,
          isShow: false,
          isUnlimited: false,
          limits: 0,
          systemInfo: {},
          isDownloading: false,
          isProcessing: false,
          scenarioDocId: '',
          isDynamic: false,
          customerCode: '',
          customerId: '',
          campaignId: '',
        })
      })
    },

    getPackageCode(packageData?: {
      name: string
      code: string
      campaignIds: string[]
    }) {
      if (!packageData) {
        return '\u{10FFFF}'
      }
      return `${packageData.name} (${packageData.code})`
    },

    getPackageIndex(
      campaignId: string,
      packageData?: {
        campaignIds: string[]
        name: string
        code: string
      }
    ) {
      if (!packageData || !packageData.campaignIds.length) {
        return 0
      }
      return packageData.campaignIds.indexOf(campaignId)
    },

    async loadConfirmedCouponCount() {
      try {
        const couponsGroupQuery = query(
          collectionGroup(db, 'coupons'),
          where('isConfirmed', '==', true)
        )

        const confirmedCouponsSnapshot = await getDocs(couponsGroupQuery)

        confirmedCouponsSnapshot.forEach((doc) => {
          const campaignId = doc.ref.parent.parent?.id
          if (!campaignId) {
            return
          }
          const foundCampaign = this.campaignData.find(
            (campaign) => campaign.campaignId === campaignId
          )

          if (!foundCampaign) {
            return
          }

          foundCampaign.confirmedCouponCount =
            (foundCampaign.confirmedCouponCount || 0) + 1
        })
      } catch (error) {
        console.error('Error loading coupon counts:', error)
      }
    },

    async getOrSetCouponCount(document: QueryDocumentSnapshot<DocumentData>) {
      if (document.data().couponCount === undefined) {
        const couponCount = await getCountFromServer(
          query(collection(db, 'campaigns', document.id, 'coupons'))
        )
        await updateDoc(doc(db, 'campaigns', document.id), {
          couponCount: couponCount.data().count,
        })
        return couponCount.data().count
      }
      return document.data().couponCount
    },

    /**
     * Utility Methods for Date/Time Handling
     */
    Utc2UnixTime(dateString: string) {
      // timezoneをZ(UTC)と指定してunixTimeを取得
      return new Date(dateString).getTime()
    },

    UnixTimeToString(UnixTime: number) {
      return moment(UnixTime).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
    },

    /**
     * Campaign Results Management Methods
     */
    async getResultsByCampaign(campaignId: string) {
      // cloud functionsとの連携
      const getResultsByCampaignIdForManagers = httpsCallable(
        functions,
        'getResultsByCampaignIdForManagers'
      )
      const inputData = {
        campaignId: campaignId,
      }
      return await getResultsByCampaignIdForManagers(inputData)
        .then((result) => {
          const data = result.data
          // console.log(data)
          return data
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    async getResultsCountByCampaignId(campaignId: string) {
      //resultListのリセット
      this.resultList = [] as ResultType[]
      const getResultsByCampaignIdForManagers = httpsCallable(
        functions,
        'getResultsByCampaignIdForManagers'
      )
      const inputData = {
        campaignId: campaignId,
      }
      return await getResultsByCampaignIdForManagers(inputData)
        .then((result) => {
          this.resultList = result.data as ResultType[]

          // 当該examのユニーク受験者数をカウントしてリターン
          // 再受験は一切考慮しない（一回でも受験していれば受験済にカウント：再受験を詳細に扱う場合はdocument/propertyやfunctionの追加が実行性能的に妥当と考えられる
          let studentIds = [] as string[]
          let resultsCount = 0
          this.resultList.forEach((target) => {
            if (target.userInfo.couponCode) {
              if (!studentIds.includes(target.userInfo.couponCode)) {
                resultsCount++
                studentIds.push(target.userInfo.couponCode)
              }
            }
          })
          return resultsCount
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    /**
     * Data Export Methods
     */
    async downloadBtnAction(campaignId: string) {
      for await (const item of this.campaignData) {
        if (item.campaignId === campaignId) {
          if (item.isProcessing) {
            break
          } else {
            item.isDownloading = true
            item.isProcessing = true
            await this.downloadData(campaignId).then(() => {
              setTimeout(() => {
                item.isDownloading = false
                item.isProcessing = false
              }, 500)
            })
          }
          break
        }
      }
    },
    // csv変換を加えたDL機能
    async downloadData(campaignId: string) {
      // console.log('running data export...')
      let jsonData = {} as ResultType[]
      jsonData = await this.downloadCampaignData(campaignId)
      if (jsonData) {
        this.downloadForCsv(jsonData)
      } else {
        downloadNoResultCsv()
      }
    },

    async downloadCampaignData(campaignId: string) {
      // console.log('download campaignId:', campaignId)
      const result = (await this.getResultsByCampaign(
        campaignId
      )) as ResultType[]
      // console.log('JSON data: ', result)
      return result
    },

    downloadForCsv(jsonData: ResultType[]) {
      const env = process.env.NODE_ENV
      let langxWebAppUrl =
        env === 'development' && window.location.hostname === 'localhost'
          ? 'http://localhost:5173'
          : env === 'development' && window.location.hostname !== 'localhost'
          ? 'https://develop-langx-learner-webapp-2aru7nyvta8n.web.app'
          : env === 'staging'
          ? 'https://staging-langx-learner-webapp-t2qbajc92vch.web.app'
          : env === 'production'
          ? 'https://speaking.langx.ai'
          : 'http://localhost:5173'

      const headers = [
        'CampaignCode',
        'CouponCode',
        'Started At',
        'Completed At',
        'CEFR Level',
        'CEFR Score',
        'Accuracy Level',
        'Accuracy Score',
        'Coherence Level',
        'Coherence Score',
        'Fluency Level',
        'Fluency Score',
        'Interaction Level',
        'Interaction Score',
        'Phonology Level',
        'Phonology Score',
        'Range Level',
        'Range Score',
        'channelName',
        'Link to shared report page',
      ]

      const rows = jsonData.map((data) => {
        const interactionLevel =
          data.fastResult.score.interaction.score === -1
            ? String(this.$t('conversationAnalysis.unrated'))
            : data.fastResult.score.interaction.cefrLevel
        const interactionScore =
          data.fastResult.score.interaction.score === -1
            ? ''
            : data.fastResult.score.interaction.score

        return [
          data.userInfo.campaignCode,
          data.userInfo.couponCode,
          this.UnixTimeToString(
            this.Utc2UnixTime(data.dialogStatus.dialogStartedAt)
          ),
          this.UnixTimeToString(
            this.Utc2UnixTime(data.dialogStatus.dialogEndedAt)
          ),
          data.fastResult.score.cefrOverall.cefrLevel,
          data.fastResult.score.cefrOverall.score,
          data.fastResult.score.accuracy.cefrLevel,
          data.fastResult.score.accuracy.score,
          data.fastResult.score.coherence.cefrLevel,
          data.fastResult.score.coherence.score,
          data.fastResult.score.fluency.cefrLevel,
          data.fastResult.score.fluency.score,
          interactionLevel,
          interactionScore,
          data.fastResult.score.phonology.cefrLevel,
          data.fastResult.score.phonology.score,
          data.fastResult.score.range.cefrLevel,
          data.fastResult.score.range.score,
          data.dialogStatus.channelName,
          `${langxWebAppUrl}/shared-report/${data.dialogStatus.channelName}`,
        ]
      })

      const csvContent = createCsvContent(headers, rows)
      downloadCsv(csvContent, `exportData_${getJstTimestamp()}.csv`)
    },

    /**
     * UI State Management Methods
     */
    closeSnackbar() {
      this.isSnackbarActionBtn = false
    },

    getShowingCampaignCount(items: CampaignType[]) {
      const count = items.filter((item) => item.isShow).length
      return this.$tc('home.campaignCount', count, { count })
    },

    getHeaders(): TableHeader[] {
      return [
        {
          text: '',
          value: 'packageCode',
          align: 'center',
          sortable: true,
          groupable: true,
        },
        {
          text: '#',
          value: 'packageIndex',
          align: 'start',
          sortable: true,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.campaignCodeOrName') as string,
          value: 'campaignCode',
          align: 'start',
          sortable: true,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.publicPeriod') as string,
          value: 'openTime',
          align: 'start',
          sortable: true,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.examPeriod') as string,
          value: 'startTime',
          align: 'start',
          sortable: true,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.couponCounts') as string,
          value: 'couponCounts',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.interviewCountPerCoupon') as string,
          value: 'interviewCountPerCoupon',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.edit') as string,
          value: 'edit',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.couponList') as string,
          value: 'couponList',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.showResults') as string,
          value: 'showResults',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('home.tableHeader.download') as string,
          value: 'download',
          align: 'start',
          sortable: false,
          groupable: false,
        },
      ]
    },

    updateHeaders() {
      this.headers = this.getHeaders()
    },

    /**
     * Coupon Dialog Management Methods
     */
    async openCampaignCouponList(campaignId: string) {
      this.couponDialogCollection = 'campaigns'
      this.couponDialogId = campaignId
      this.isCouponDialogVisible = true
    },

    async openPackageCouponList(packageId: string) {
      this.couponDialogCollection = 'packages'
      this.couponDialogId = packageId
      this.isCouponDialogVisible = true
    },

    resetCouponDialogState() {
      this.couponDialogId = null as null | string
      this.couponDialogCollection = null as null | 'campaigns' | 'packages'
    },
  },
})
</script>
