<style scoped>
.dialog-btn {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-btn--download {
  width: 5rem;
}
</style>

<template>
  <div>
    <v-row>
      <v-col class="mt-7 mb-n4">
        <div
          style="text-align: center"
          v-html="
            $t('viewCouponLists.couponListDialog.unusedCouponEditDescription')
          "
        ></div>
      </v-col>
    </v-row>
    <v-row class="mt-5 mb-n10 text-body-2" style="justify-content: center">
      <v-col cols="6">
        <v-select
          v-model="selectedFilter"
          :items="filterOptions"
          :label="$t('viewCouponLists.couponListDialog.filter')"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="2" class="mt-2">
        <v-btn
          class="dialog-btn dialog-btn--download"
          color="#1b2643"
          dark
          @click="handleDownload"
        >
          <v-icon color="white">mdi-folder-zip-outline</v-icon>
          DL
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CouponListControls',

  props: {
    filterValue: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filterOptions: [
        this.$t('viewCouponLists.couponListDialog.filterOption.all'),
        this.$t(
          'viewCouponLists.couponListDialog.filterOption.usedCouponsOnly'
        ),
        this.$t(
          'viewCouponLists.couponListDialog.filterOption.unusedCouponsOnly'
        ),
      ],
    }
  },

  computed: {
    selectedFilter: {
      get(): string {
        return this.filterValue
      },
      set(value: string) {
        this.$emit('update:filterValue', value)
      },
    },
  },

  methods: {
    handleDownload() {
      this.$emit('download')
    },
  },
})
</script>
