import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VRow,[_c(VCol,{staticClass:"mt-7 mb-n4"},[_c('div',{staticStyle:{"text-align":"center"},domProps:{"innerHTML":_vm._s(
          _vm.$t('viewCouponLists.couponListDialog.unusedCouponEditDescription')
        )}})])],1),_c(VRow,{staticClass:"mt-5 mb-n10 text-body-2",staticStyle:{"justify-content":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"items":_vm.filterOptions,"label":_vm.$t('viewCouponLists.couponListDialog.filter'),"outlined":""},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1),_c(VCol,{staticClass:"mt-2",attrs:{"cols":"2"}},[_c(VBtn,{staticClass:"dialog-btn dialog-btn--download",attrs:{"color":"#1b2643","dark":""},on:{"click":_vm.handleDownload}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-folder-zip-outline")]),_vm._v(" DL ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }