<style scoped>
/* Card styles */
.dialog-card--medium {
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}

/* Button styles */
.dialog-btn {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-btn--large {
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

/* Header styles */
.dialog-header {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: white;
  font-size: 14px;
}

/* Table styles */
.dialog-table td {
  border-bottom: 1px solid #dbdbdb;
}

.dialog-table__column--display-name {
  min-width: 160px;
  max-width: 160px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <div>
    <v-dialog v-model="dialogVisible" width="700px">
      <v-card>
        <v-card-title class="justify-center dialog-header">{{
          $t('viewCouponLists.couponListDialog.couponList')
        }}</v-card-title>
        <coupon-statistics-card
          :code="code"
          :name="name"
          :total-coupon-count="totalCouponCount"
          :used-coupon-count="usedCouponCount"
          :unused-coupon-count="unusedCouponCount"
          :is-loading="isLoadingCoupons"
        />
        <coupon-list-controls
          :filter-value.sync="selectedFilter"
          @download="handleDownload"
        />
        <v-row class="mx-16">
          <v-col>
            <v-pagination
              v-model="paginationState.currentPage"
              :length="totalPages"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-row class="mt-2 text-body-2" style="text-align: center">
          <v-col>
            {{ paginationRangeText }}
            {{ $t('viewCouponLists.couponListDialog.case') }}
          </v-col>
        </v-row>
        <coupon-list-table
          :coupons="currentPageCoupons"
          :is-loading="isLoadingCoupons"
          :load-progress="loadProgress"
          :current-page="paginationState.currentPage"
          :items-per-page="paginationState.itemsPerPage"
          @edit="handleEdit"
        />
        <v-row class="mt-2 mx-16">
          <v-col>
            <v-pagination
              v-model="paginationState.currentPage"
              :length="totalPages"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn
            class="mx-2 mt-3 mb-3 px-5 dialog-btn dialog-btn--large"
            color="#1b2643"
            dark
            @click="handleClose"
          >
            {{ $t('viewCouponLists.couponListDialog.tableHeader.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Coupon Dialog -->
    <edit-coupon-dialog
      v-if="isEditCouponDialogVisible"
      :isVisible.sync="isEditCouponDialogVisible"
      :coupon="activeCoupon"
      :coupons="coupons"
      :type="type"
      :id="id"
      @success="handleOnSuccess"
    />
  </div>
</template>
<script lang="ts">
import { CouponListItem } from '@/resources/coupon'
import { downloadCouponListAsCsv, getCouponList } from '@/utils'
import Vue from 'vue'
import EditCouponDialog from './EditCouponDialog.vue'
import CouponStatisticsCard from '../CouponList/CouponStatisticsCard.vue'
import CouponListControls from '../CouponList/CouponListControls.vue'
import CouponListTable from '../CouponList/CouponListTable.vue'

export default Vue.extend({
  name: 'CouponListDialog',

  components: {
    EditCouponDialog,
    CouponStatisticsCard,
    CouponListControls,
    CouponListTable,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    type: {
      type: String as () => 'campaigns' | 'packages',
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      code: '',
      name: '',
      totalCouponCount: 0,
      usedCouponCount: 0,
      unusedCouponCount: 0,
      coupons: [] as CouponListItem[],
      isLoadingCoupons: false,
      loadProgress: 0,
      paginationState: {
        currentPage: 1,
        itemsPerPage: 10000,
      },
      selectedFilter: this.$t(
        'viewCouponLists.couponListDialog.filterOption.all'
      ),
      isEditCouponDialogVisible: false,
      activeCoupon: null as CouponListItem | null,
    }
  },

  computed: {
    dialogVisible: {
      get(): boolean {
        return this.isVisible
      },
      set(value: boolean) {
        this.$emit('update:isVisible', value)
      },
    },
    filteredCoupons(): CouponListItem[] {
      return this.coupons.filter(this.filterCoupons)
    },
    currentPageCoupons(): CouponListItem[] {
      if (this.couponPages.length === 0) return []
      return this.couponPages[this.paginationState.currentPage - 1] || []
    },
    totalPages(): number {
      return this.couponPages.length
    },
    paginationRangeText(): string {
      const { currentPage, itemsPerPage } = this.paginationState

      // Calculate start index (e.g. for page 2 with 10 items per page: (2-1) * 10 + 1 = 11)
      // The same as offset calculation in CouponListTable.vue
      const startIndex = (currentPage - 1) * itemsPerPage + 1

      // Calculate end index - either itemsPerPage count or total remaining items
      // e.g. if page 2 has only 5 items left, use 5 instead of full itemsPerPage
      const endIndex =
        currentPage * itemsPerPage < this.filteredCoupons.length
          ? currentPage * itemsPerPage
          : this.filteredCoupons.length

      return `${startIndex} ~ ${endIndex}`
    },
    couponPages(): CouponListItem[][] {
      const { itemsPerPage } = this.paginationState
      return Array.from(
        { length: Math.ceil(this.filteredCoupons.length / itemsPerPage) },
        (_, i) =>
          this.filteredCoupons.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
      )
    },
  },

  watch: {
    isVisible: {
      immediate: true,
      handler(newVal: boolean) {
        if (newVal) {
          this.loadCouponData()
        }
      },
    },
  },

  methods: {
    async loadCouponData() {
      try {
        this.isLoadingCoupons = true
        const data = await getCouponList(this.type, this.id, (progress) => {
          this.loadProgress = progress
        })
        this.code = data.code
        this.name = data.name
        this.totalCouponCount = data.totalCouponCount
        this.usedCouponCount = data.usedCouponCount
        this.unusedCouponCount = data.unusedCouponCount
        this.coupons = data.coupons
      } catch (error) {
        console.error('Error loading coupon data:', error)
      } finally {
        this.isLoadingCoupons = false
      }
    },

    filterCoupons(coupon: CouponListItem): boolean {
      if (
        this.selectedFilter ===
        this.$t('viewCouponLists.couponListDialog.filterOption.all')
      ) {
        return true
      }
      if (
        this.selectedFilter ===
        this.$t('viewCouponLists.couponListDialog.filterOption.usedCouponsOnly')
      ) {
        return coupon.isConfirmed
      }
      if (
        this.selectedFilter ===
        this.$t(
          'viewCouponLists.couponListDialog.filterOption.unusedCouponsOnly'
        )
      ) {
        return !coupon.isConfirmed
      }
      return true
    },

    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    },

    handleDownload() {
      downloadCouponListAsCsv(this.coupons)
    },

    handleEdit(item: CouponListItem) {
      this.activeCoupon = item
      this.isEditCouponDialogVisible = true
    },

    handleOnSuccess() {
      // Reload coupon data after modification
      this.loadCouponData()
    },
  },
})
</script>
