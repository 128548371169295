import { render, staticRenderFns } from "./EditCouponDialog.vue?vue&type=template&id=36d1ecc6&scoped=true"
import script from "./EditCouponDialog.vue?vue&type=script&lang=ts"
export * from "./EditCouponDialog.vue?vue&type=script&lang=ts"
import style0 from "./EditCouponDialog.vue?vue&type=style&index=0&id=36d1ecc6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d1ecc6",
  null
  
)

export default component.exports