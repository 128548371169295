import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mx-auto dialog-card--medium mt-8",attrs:{"width":"85%"}},[(_vm.isLoading)?[_c(VProgressLinear,{attrs:{"indeterminate":""}}),_c('p',{staticClass:"w-full text-center primary--text text-caption"},[_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.progress.loadingCouponCounts'))+" ")])]:_vm._e(),_c(VCardTitle,[_vm._v(_vm._s(_vm.code))]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponListDialog.totalCoupons'))+" "+_vm._s(_vm.totalCouponCount)),_c('br'),_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponListDialog.usedCoupons'))+" "+_vm._s(_vm.usedCouponCount)),_c('br'),_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponListDialog.unusedCoupons'))+" "+_vm._s(_vm.unusedCouponCount)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }