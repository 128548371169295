import { CouponListItem } from '@/resources/coupon'

import { getDoc } from 'firebase/firestore'
import { DocumentReference } from 'firebase/firestore'

/**
 * Checks if a coupon has been confirmed/used
 * @param couponRef - Firestore document reference to the coupon
 * @returns Promise<boolean> - True if coupon exists and is confirmed, false otherwise
 * @throws Error if checking confirmation status fails
 */
export async function isCouponConfirmed(
  couponRef: DocumentReference
): Promise<boolean> {
  try {
    const couponDoc = await getDoc(couponRef)
    return couponDoc.exists() ? couponDoc.data()?.isConfirmed ?? false : false
  } catch (error) {
    console.error('Error checking coupon confirmation status:', error)
    throw error
  }
}

export function isCouponCodeUnique(
  couponCode: string,
  couponList: CouponListItem[]
) {
  return !couponList.some((coupon) => coupon.couponCode === couponCode)
}
