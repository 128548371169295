import { db } from '@/plugins/firebase'
import { Package } from '@/resources/package'
import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore'

import { DocumentData } from 'firebase/firestore'

import { QuerySnapshot } from 'firebase/firestore'

const loadedPackages: {
  [key: string]: QuerySnapshot<DocumentData> | DocumentSnapshot<DocumentData>
} = {}

export async function getPackageById(packageId: string, forceRefresh = false) {
  let snapshot = loadedPackages[packageId] as DocumentSnapshot<DocumentData>

  if (forceRefresh) {
    delete loadedPackages[packageId]
  }

  if (!snapshot) {
    const packageRef = doc(db, 'packages', packageId)
    snapshot = await getDoc(packageRef)
  }

  if (!snapshot.exists()) {
    return null
  } else {
    loadedPackages[packageId] = snapshot

    const data = snapshot.data() as Package

    return {
      ...data,
      id: snapshot.id,
    }
  }
}

export async function invalidatePackageById(packageId: string) {
  delete loadedPackages[packageId]
}

export async function getPackageListByCustomerId(
  customerId: string
): Promise<QuerySnapshot<DocumentData>> {
  const packagesRef = collection(db, 'packages')
  return await getDocs(
    query(
      packagesRef,
      where('customerId', '==', customerId),
      where('deletedAt', '==', null),
      orderBy('name', 'asc')
    )
  )
}
