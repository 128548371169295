import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

// Convert UTC to JST
export const getJstTime = (date: string) => {
  return dayjs.utc(date).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
}

/**
 * Gets the current time in Japan Standard Time (JST) as Unix timestamp in milliseconds.
 *
 * @returns {number} Current JST time as Unix timestamp in milliseconds (e.g. 1737485222000)
 *
 * @example
 * const jstTime = getJstTimestamp();
 * // Returns e.g. 1737485222000
 */
export const getJstTimestamp = () => {
  return dayjs().tz('Asia/Tokyo').valueOf()
}

export const calculateDuration = (start: string, end: string): string => {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  const diffMs = endDate.diff(startDate)
  const durationObj = dayjs.duration(Math.abs(diffMs))
  const totalHours = String(Math.floor(durationObj.asHours())).padStart(2, '0')
  const isNegative = diffMs < 0

  return `${isNegative ? '-' : ''}${totalHours}:${durationObj.format('mm:ss')}`
}

/**
 * Creates an ISO UTC datetime string for database storage.
 * Converts a local Tokyo time to UTC, or returns current UTC time if no date provided.
 *
 * @param {string|null} date - Date in YYYY-MM-DD format. If null, current UTC datetime is returned
 * @param {string} [time='00:00'] - Time in HH:mm format. Defaults to midnight if not provided
 * @returns {string} ISO 8601 UTC datetime string in format YYYY-MM-DDTHH:mm:ss.SSSZ
 *
 * @example
 * // Get current UTC time
 * const now = createIsoUtcDateTime();
 * // Returns e.g. "2024-03-20T15:30:00.000Z"
 *
 * // Convert Tokyo time to UTC
 * const utcTime = createIsoUtcDateTime("2024-03-20", "15:30");
 * // Returns "2024-03-20T06:30:00.000Z" (Tokyo is UTC+9)
 *
 * // Midnight in Tokyo
 * const midnight = createIsoUtcDateTime("2024-03-20");
 * // Returns "2024-03-19T15:00:00.000Z" (previous day UTC)
 */
export function createIsoUtcDateTime(
  date: string | null = null,
  time = '00:00'
): string {
  if (!date) {
    return dayjs.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  }

  return dayjs
    .tz(`${date} ${time}`, 'Asia/Tokyo')
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
}
