import { getDoc, runTransaction } from 'firebase/firestore'
import { db } from '@/plugins/firebase'
import { doc } from 'firebase/firestore'
import { isCouponConfirmed } from './couponUtils'
import { createCouponModificationNotAllowedError } from './errorUtils'
import { EditableCoupon } from '@/resources/coupon'

/**
 * Edits a coupon in a package/campaign. If editing a package coupon, also updates associated campaigns.
 * @param collectionName - Type of collection ('packages' or 'campaigns')
 * @param id - ID of the package/campaign containing the coupon
 * @param couponCode - Current code of the coupon
 * @param newCouponData - New coupon data to update with
 * @throws Error if edit fails or if coupon is confirmed
 */
export async function editCoupon(
  collectionName: 'packages' | 'campaigns',
  id: string,
  couponCode: string,
  newCouponData: EditableCoupon
): Promise<void> {
  const currentCouponRef = doc(db, collectionName, id, 'coupons', couponCode)
  const newCouponRef = doc(
    db,
    collectionName,
    id,
    'coupons',
    newCouponData.couponCode
  )

  try {
    // Check if the coupon is confirmed
    const isConfirmed = await isCouponConfirmed(currentCouponRef)
    if (isConfirmed) {
      throw createCouponModificationNotAllowedError(couponCode)
    }

    // Use transaction for atomic operation
    await runTransaction(db, async (transaction) => {
      transaction.delete(currentCouponRef)
      transaction.set(newCouponRef, newCouponData)
    })

    // If editing a package coupon, update associated campaigns
    if (collectionName === 'packages') {
      const packageDoc = await getDoc(doc(db, 'packages', id))
      if (!packageDoc.exists()) {
        return
      }

      const campaignIds = packageDoc.data().campaignIds || []
      await Promise.all(
        campaignIds.map((campaignId: string) =>
          editCoupon('campaigns', campaignId, couponCode, newCouponData)
        )
      )
    }
  } catch (error) {
    console.error(
      `Failed to edit coupon ${couponCode} in ${collectionName}/${id}:`,
      error
    )
    throw error
  }
}
