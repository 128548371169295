<style scoped>
.dialog-card {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-card--large {
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.dialog-card--medium {
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}

.dialog-btn {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-btn--large {
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.dialog-btn--medium {
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.dialog-header {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: white;
  font-size: 14px;
}

.dialog-header__text--active {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.dialog-table td {
  border-bottom: 1px solid #dbdbdb;
}

.dialog-input {
  font-size: 14px;
}

.dialog-table__column--display-name {
  min-width: 160px;
  max-width: 160px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <div>
    <v-dialog v-model="dialogVisible" width="550px">
      <v-card class="mx-auto dialog-card dialog-card--large">
        <v-card-title class="justify-center dialog-header">
          {{ $t('viewCouponLists.couponEditDialog.couponEdit') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="dialog-input mx-5 mt-10"
            v-model="couponCodeField"
            :label="$t('viewCouponLists.couponEditDialog.couponCode')"
            persistent-placeholder
            outlined
            required
            :disabled="coupon.isConfirmed"
            :rules="[rules.required, rules.halfSize]"
          ></v-text-field>
          <v-text-field
            class="dialog-input mx-5 mt-1 mb-n8"
            v-model="passwordField"
            :label="$t('viewCouponLists.couponEditDialog.password')"
            persistent-placeholder
            outlined
            required
            :disabled="coupon.isConfirmed"
            :rules="[rules.required, rules.halfSize]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center pb-6">
          <v-btn
            class="dialog-btn dialog-btn--large white--text"
            color="#1b2643"
            @click="handleConfirmEdit"
            :disabled="!isValid"
          >
            {{ $t('viewCouponLists.couponEditDialog.confirm') }}
          </v-btn>
          <v-btn
            class="dialog-btn dialog-btn--large white--text"
            color="error"
            @click="handleDelete"
            :disabled="!isValid"
          >
            {{ $t('viewCouponLists.couponEditDialog.delete') }}
          </v-btn>
          <v-btn
            class="dialog-btn dialog-btn--large"
            color="#1b2643"
            dark
            @click="handleCancel"
          >
            {{ $t('viewCouponLists.couponEditDialog.cancle') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Coupon Confirmation Dialog -->
    <edit-coupon-confirmation-dialog
      v-if="isConfirmEditDialogVisible"
      :isVisible.sync="isConfirmEditDialogVisible"
      :coupon="coupon"
      :newCouponCode="couponCodeField"
      :newPassword="passwordField"
      @confirm="handleEditConfirmed"
    />

    <!-- Delete Coupon Confirmation Dialog -->
    <delete-coupon-confirmation-dialog
      v-if="isConfirmDeleteDialogVisible"
      :isVisible.sync="isConfirmDeleteDialogVisible"
      :coupon="coupon"
      @confirm="handleDeleteConfirmed"
    />

    <!-- Edit Coupon Error Dialog -->
    <edit-coupon-error-dialog
      v-if="isErrorDialogVisible"
      :isVisible.sync="isErrorDialogVisible"
      :coupon="coupon"
      :isCouponConfirmed="isCouponConfirmed"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { CouponListItem } from '@/resources/coupon'
import {
  deleteCoupon,
  editCoupon,
  isCouponCodeUnique,
  isCouponModificationNotAllowedError,
} from '@/utils'
import EditCouponConfirmationDialog from './EditCouponConfirmationDialog.vue'
import DeleteCouponConfirmationDialog from './DeleteCouponConfirmationDialog.vue'
import EditCouponErrorDialog from './EditCouponErrorDialog.vue'

export default Vue.extend({
  name: 'EditCouponDialog',

  components: {
    EditCouponConfirmationDialog,
    DeleteCouponConfirmationDialog,
    EditCouponErrorDialog,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    coupon: {
      type: Object as () => CouponListItem,
      required: true,
    },
    coupons: {
      type: Array as () => CouponListItem[],
      required: true,
    },
    type: {
      type: String as () => 'campaigns' | 'packages',
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      couponCodeField: this.coupon.couponCode,
      passwordField: this.coupon.password,
      rules: {
        required: (value: string) => !!value || '未入力です',
        halfSize: (value: string) =>
          /^[a-zA-Z0-9_-]*$/.test(value) ||
          '半角英数字・ハイフン・アンダーバーで入力してください',
      },

      // Edit Coupon Confirmation Dialog
      isConfirmEditDialogVisible: false,

      // Delete Coupon Confirmation Dialog
      isConfirmDeleteDialogVisible: false,

      // Edit Coupon Error Dialog
      isErrorDialogVisible: false,
      isCouponConfirmed: this.coupon.isConfirmed,
    }
  },

  computed: {
    dialogVisible: {
      get(): boolean {
        return this.isVisible
      },
      set(value: boolean) {
        this.$emit('update:isVisible', value)
      },
    },
    isValid(): boolean {
      // Check required rule
      if (
        !this.rules.required(this.couponCodeField) ||
        !this.rules.required(this.passwordField)
      ) {
        return false
      }

      // Check half-size rule
      if (
        !this.rules.halfSize(this.couponCodeField) ||
        !this.rules.halfSize(this.passwordField)
      ) {
        return false
      }

      return true
    },
  },

  methods: {
    handleConfirmEdit() {
      this.isConfirmEditDialogVisible = true
    },

    handleDelete() {
      this.isConfirmDeleteDialogVisible = true
    },

    handleCancel() {
      this.dialogVisible = false
    },

    async handleEditConfirmed() {
      try {
        const isUnique = isCouponCodeUnique(
          this.couponCodeField,
          this.coupons.filter(
            (coupon) => coupon.couponCode !== this.coupon.couponCode
          )
        )

        if (!isUnique) {
          this.isErrorDialogVisible = true
          return
        }

        await editCoupon(this.type, this.id, this.coupon.couponCode, {
          couponCode: this.couponCodeField,
          password: this.passwordField,
        })

        this.$emit('success')
        this.isConfirmEditDialogVisible = false
        this.dialogVisible = false
      } catch (error) {
        if (
          error instanceof Error &&
          isCouponModificationNotAllowedError(error)
        ) {
          this.isCouponConfirmed = true
        }
        this.isErrorDialogVisible = true
      }
    },

    async handleDeleteConfirmed() {
      try {
        await deleteCoupon(this.type, this.id, this.coupon.couponCode)

        this.$emit('success')
        this.isConfirmDeleteDialogVisible = false
        this.dialogVisible = false
      } catch (error) {
        if (
          error instanceof Error &&
          isCouponModificationNotAllowedError(error)
        ) {
          this.isCouponConfirmed = true
        }
        this.isErrorDialogVisible = true
      }
    },
  },
})
</script>
