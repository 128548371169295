import { Coupon, CouponList, CouponListItem } from '@/resources/coupon'
import { getCampaignById } from './campaignUtils'
import { getPackageById } from './packageUtils'
import {
  collection,
  DocumentData,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
} from 'firebase/firestore'
import { getDocs } from 'firebase/firestore'
import { db } from '@/plugins/firebase'
import { getLearnerDisplayName } from './learnersUtils'

export async function getCouponList(
  collectionName: 'packages' | 'campaigns',
  docId: string,
  onProgress?: (progress: number) => void
): Promise<CouponList> {
  const couponList: CouponList = {
    code: '',
    name: '',
    totalCouponCount: 0,
    usedCouponCount: 0,
    unusedCouponCount: 0,
    coupons: [],
  }

  const couponInfo = await getCouponInfo(collectionName, docId)
  if (!couponInfo) return couponList

  couponList.code = couponInfo.code
  couponList.name = couponInfo.name
  couponList.totalCouponCount = couponInfo.totalCouponCount

  const coupons = await getCoupons(
    collectionName,
    docId,
    couponInfo.totalCouponCount,
    onProgress
  )
  if (!coupons) return couponList

  const couponStats = await getCouponStats(coupons)
  if (!couponStats) return couponList

  // Update total coupon count with the actual number of coupons
  couponList.totalCouponCount = couponStats.totalCouponCount
  couponList.usedCouponCount = couponStats.usedCouponCount
  couponList.unusedCouponCount = couponStats.unusedCouponCount

  couponList.coupons = await Promise.all(coupons.docs.map(mapCouponToLearner))

  return couponList
}

async function getCouponInfo(
  collectionName: 'packages' | 'campaigns',
  docId: string
): Promise<{
  code: string
  name: string
  totalCouponCount: number
} | null> {
  if (collectionName === 'packages') {
    const packageData = await getPackageById(docId, true)
    if (!packageData) return null
    return {
      code: packageData.code,
      name: packageData.name,
      totalCouponCount: packageData.couponCount,
    }
  } else if (collectionName === 'campaigns') {
    const campaign = await getCampaignById(docId, true)
    if (!campaign) return null
    return {
      code: campaign.campaignCode,
      name: campaign.name,
      totalCouponCount: campaign.couponCount,
    }
  }
  return null
}

async function getCouponStats(coupons: QuerySnapshot<DocumentData>) {
  const totalCoupons = coupons.size
  const confirmedCoupons = coupons.docs.filter(
    (doc) => doc.data().isConfirmed || doc.data().learnerId
  ).length
  const unconfirmedCoupons = totalCoupons - confirmedCoupons

  return {
    totalCouponCount: totalCoupons,
    usedCouponCount: confirmedCoupons,
    unusedCouponCount: unconfirmedCoupons,
  }
}

async function mapCouponToLearner(
  doc: QueryDocumentSnapshot<DocumentData>
): Promise<CouponListItem> {
  const couponData = doc.data() as Coupon
  const { couponCode, password, isConfirmed, learnerId } = couponData
  const displayName = learnerId ? await getLearnerDisplayName(learnerId) : ''

  return {
    couponCode,
    password,
    isConfirmed: isConfirmed ?? learnerId ? true : false,
    displayName,
  }
}

function calculateProgress(
  current: number,
  total: number,
  isComplete = false
): number {
  if (isComplete) return 100
  return Math.min(Math.floor((current / total) * 100), 99)
}

async function getCoupons(
  collectionName: 'packages' | 'campaigns',
  docId: string,
  totalCoupons: number,
  onProgress?: (progress: number) => void
): Promise<QuerySnapshot<DocumentData> | undefined> {
  const BATCH_SIZE = 5000
  const couponsRef = collection(db, collectionName, docId, 'coupons')
  const baseQuery = query(couponsRef, orderBy('couponCode'), limit(BATCH_SIZE))

  // Fetch first batch of coupons
  const initialBatch = await getDocs(baseQuery)
  // If we got less than BATCH_SIZE docs, we have all the coupons
  if (initialBatch.empty || initialBatch.size < BATCH_SIZE) {
    onProgress?.(calculateProgress(0, 0, true))
    return initialBatch
  }

  let processedCount = initialBatch.size
  onProgress?.(calculateProgress(processedCount, totalCoupons))

  /**
   * Recursively fetches remaining batches of coupons.
   * Uses the last coupon code from previous batch as cursor for pagination.
   */
  const fetchRemainingBatches = async (
    accDocs: QueryDocumentSnapshot<DocumentData>[],
    lastDoc: QueryDocumentSnapshot<DocumentData>
  ): Promise<QueryDocumentSnapshot<DocumentData>[]> => {
    const nextBatch = await getDocs(
      query(
        couponsRef,
        orderBy('couponCode'),
        startAfter(lastDoc.data().couponCode),
        limit(BATCH_SIZE)
      )
    )

    processedCount += nextBatch.size
    onProgress?.(calculateProgress(processedCount, totalCoupons))

    // If batch is not full, we've reached the end
    if (nextBatch.empty || nextBatch.size < BATCH_SIZE) {
      onProgress?.(calculateProgress(0, 0, true))
      return [...accDocs, ...nextBatch.docs]
    }

    // Otherwise keep fetching remaining batches
    return fetchRemainingBatches(
      [...accDocs, ...nextBatch.docs],
      nextBatch.docs[nextBatch.docs.length - 1]
    )
  }

  // Start recursive batch fetching with initial batch
  const allDocs = await fetchRemainingBatches(
    initialBatch.docs,
    initialBatch.docs[initialBatch.docs.length - 1]
  )

  // Construct final snapshot with all documents
  const finalSnapshot = {
    ...initialBatch,
    docs: allDocs,
    size: allDocs.length,
  } as QuerySnapshot<DocumentData>

  return finalSnapshot
}
