<style scoped>
.dialog-card--medium {
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}

.dialog-table td {
  border-bottom: 1px solid #dbdbdb;
}

.dialog-table__column--display-name {
  min-width: 160px;
  max-width: 160px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <v-card width="85%" class="mx-auto mt-5 dialog-card--medium" elevation="0">
    <template v-if="isLoading">
      <v-progress-linear
        :model-value="loadProgress"
        color="primary"
        @update:model-value="$emit('update:loadProgress', $event)"
      >
      </v-progress-linear>
      <p class="w-full text-center primary--text text-caption">
        {{
          $t('viewCouponLists.couponListDialog.progress.loadingCouponList', {
            progressValue: loadProgress,
          })
        }}
      </p>
    </template>
    <v-simple-table class="pr-5 pl-5 pt-2 pb-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th>{{ $t('viewCouponLists.couponListDialog.tableHeader.no') }}</th>
            <th>
              {{
                $t('viewCouponLists.couponListDialog.tableHeader.couponCode')
              }}
            </th>
            <th>
              {{ $t('viewCouponLists.couponListDialog.tableHeader.password') }}
            </th>
            <th class="dialog-table__column--display-name">
              {{ $t('viewCouponLists.couponListDialog.tableHeader.username') }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="black"
                    small
                    class="ml-1"
                    @click="handleDisplayNameVisibility"
                  >
                    {{ eyeIcon }}
                  </v-icon>
                </template>
                <span>{{ tooltipText }}</span>
              </v-tooltip>
            </th>
            <th>
              {{ $t('viewCouponLists.couponListDialog.tableHeader.edit') }}
            </th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in coupons" :key="item.couponCode">
          <tr>
            <td>{{ getRowNumber(index) }}</td>
            <td>{{ item.couponCode }}</td>
            <td>{{ item.password }}</td>
            <td class="dialog-table__column--display-name">
              {{ getDisplayName(item) }}
            </td>
            <td>
              <a
                href="javascript:void(0)"
                v-show="shouldShowEditButton(item)"
                @click="handleEdit(item)"
                >{{
                  $t('viewCouponLists.couponListDialog.tableHeader.editCoupon')
                }}</a
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { CouponListItem } from '@/resources/coupon'

export default Vue.extend({
  name: 'CouponListTable',

  props: {
    coupons: {
      type: Array as () => CouponListItem[],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadProgress: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isNameVisible: false,
    }
  },

  computed: {
    eyeIcon(): string {
      return this.isNameVisible ? 'mdi mdi-eye' : 'mdi mdi-eye-off'
    },
    tooltipText(): string {
      return this.isNameVisible
        ? String(
            this.$t(
              'viewCouponLists.couponListDialog.tableHeader.tooltipMessage.hideNames'
            )
          )
        : String(
            this.$t(
              'viewCouponLists.couponListDialog.tableHeader.tooltipMessage.showNames'
            )
          )
    },
  },

  methods: {
    getRowNumber(index: number): number {
      const offset = (this.currentPage - 1) * this.itemsPerPage
      return index + 1 + offset
    },

    getDisplayName(item: CouponListItem): string {
      if (!this.isNameVisible && item.displayName) {
        return '****'
      }
      return item.displayName
    },

    shouldShowEditButton(item: CouponListItem): boolean {
      return !item.isConfirmed
    },

    handleDisplayNameVisibility() {
      this.isNameVisible = !this.isNameVisible
    },

    handleEdit(item: CouponListItem) {
      this.$emit('edit', item)
    },
  },
})
</script>
