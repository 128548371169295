import { render, staticRenderFns } from "./ConfirmCreateCampaignTable.vue?vue&type=template&id=ecfaf94c&scoped=true&%23default=dialog"
import script from "./ConfirmCreateCampaignTable.vue?vue&type=script&lang=ts"
export * from "./ConfirmCreateCampaignTable.vue?vue&type=script&lang=ts"
import style0 from "./ConfirmCreateCampaignTable.vue?vue&type=style&index=0&id=ecfaf94c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecfaf94c",
  null
  
)

export default component.exports