import { render, staticRenderFns } from "./PackageResults.vue?vue&type=template&id=293fa320&scoped=true"
import script from "./PackageResults.vue?vue&type=script&lang=ts"
export * from "./PackageResults.vue?vue&type=script&lang=ts"
import style0 from "./PackageResults.vue?vue&type=style&index=0&id=293fa320&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "293fa320",
  null
  
)

export default component.exports