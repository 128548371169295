/**
 * Creates an error for when attempting to modify a confirmed coupon
 * @param couponCode Code of the confirmed coupon
 * @returns Error object with name set to 'CouponModificationNotAllowedError'
 */
export function createCouponModificationNotAllowedError(
  couponCode: string
): Error {
  const error = new Error(
    `Cannot modify coupon ${couponCode} because it has already been confirmed/used`
  )
  error.name = 'CouponModificationNotAllowedError'
  return error
}

export function isCouponModificationNotAllowedError(error: Error): boolean {
  return error.name === 'CouponModificationNotAllowedError'
}
