<style scoped>
.dialog-card {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-card--large {
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.dialog-card--medium {
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}

.dialog-btn {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-btn--large {
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.dialog-btn--medium {
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.dialog-header {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: white;
  font-size: 14px;
}

.dialog-header__text--active {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.dialog-table td {
  border-bottom: 1px solid #dbdbdb;
}

.dialog-input {
  font-size: 14px;
}

.dialog-table__column--display-name {
  min-width: 160px;
  max-width: 160px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <v-dialog v-model="dialogVisible" width="500px">
    <v-card class="mx-auto dialog-card dialog-card--large">
      <v-card-title class="justify-center dialog-header">
        {{ $t('viewCouponLists.couponEditConfirmDialog.couponEdit') }}
      </v-card-title>
      <v-card-text>
        <p class="text-center mt-6">
          <b>{{
            $t(
              'viewCouponLists.couponEditConfirmDialog.confirmCouponEditMessage'
            )
          }}</b>
        </p>
        <v-card class="mx-3 dialog-card--medium">
          <v-card-text>
            <p class="text-center">
              {{ $t('viewCouponLists.couponEditConfirmDialog.before') }}
            </p>
            {{ $t('viewCouponLists.couponEditConfirmDialog.couponCode') }}
            {{ coupon.couponCode }}<br />
            {{ $t('viewCouponLists.couponEditConfirmDialog.password') }}
            {{ coupon.password }}
          </v-card-text>
        </v-card>
        <v-row justify="center" class="my-5">
          <v-icon>mdi-arrow-down</v-icon>
        </v-row>
        <v-card class="mx-3 dialog-card--medium">
          <v-card-text>
            <p class="text-center">
              {{ $t('viewCouponLists.couponEditConfirmDialog.after') }}
            </p>
            {{ $t('viewCouponLists.couponEditConfirmDialog.couponCode') }}
            {{ newCouponCode }}<br />
            {{ $t('viewCouponLists.couponEditConfirmDialog.password') }}
            {{ newPassword }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-center pb-6">
        <v-btn
          color="#1b2643"
          class="dialog-btn dialog-btn--large px-7"
          dark
          @click="handleConfirm"
        >
          {{ $t('viewCouponLists.couponEditConfirmDialog.edit') }}
        </v-btn>
        <v-btn
          color="#1b2643"
          class="dialog-btn dialog-btn--large"
          dark
          @click="handleCancel"
        >
          {{ $t('viewCouponLists.couponEditConfirmDialog.cancle') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import { CouponListItem } from '@/resources/coupon'

export default Vue.extend({
  name: 'EditCouponConfirmationDialog',

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    coupon: {
      type: Object as () => CouponListItem,
      required: true,
    },
    newCouponCode: {
      type: String,
      required: true,
    },
    newPassword: {
      type: String,
      required: true,
    },
  },

  computed: {
    dialogVisible: {
      get(): boolean {
        return this.isVisible
      },
      set(value: boolean) {
        this.$emit('update:isVisible', value)
      },
    },
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm')
    },

    handleCancel() {
      this.dialogVisible = false
    },
  },
})
</script>
