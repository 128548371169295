/**
 * Creates a CSV string from headers and data rows.
 * NOTE: Does not currently support escaping of values containing commas or quotes.
 *
 * @param headers - Array of column header strings
 * @param rows - 2D array of row data, where each row is an array of strings or numbers
 * @returns CSV formatted string with headers and data rows separated by newlines
 *
 * @example
 * const headers = ['Name', 'Age'];
 * const rows = [['John', 30], ['Jane', 25]];
 * const csv = createCsvContent(headers, rows);
 * // Returns: "Name,Age\nJohn,30\nJane,25"
 */
export function createCsvContent(
  headers: string[],
  rows: (string | number)[][]
): string {
  return [headers.join(','), ...rows.map((row) => row.join(','))].join('\n')
}

/**
 * Downloads data as a CSV file by creating a temporary link element.
 *
 * @param content - CSV content to be downloaded
 * @param filename - Name of the file to be downloaded (should include .csv extension)
 *
 * @example
 * const csvContent = 'Name,Age\nJohn,30';
 * downloadCsv(csvContent, 'users.csv');
 */
export function downloadCsv(content: string, filename: string) {
  const blob = new Blob([`\uFEFF${content}`], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()

  URL.revokeObjectURL(url)
  link.remove()
}
