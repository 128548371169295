<style scoped>
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 1s;
}
.v-enter-to {
  opacity: 1;
}
.background {
  background-image: linear-gradient(
    to left top,
    #536976,
    #495e6e,
    #3f5366,
    #37485e,
    #303d55,
    #2b374f,
    #273049,
    #232a43,
    #1f273e,
    #1b2439,
    #172135,
    #141e30
  );
}
.language-select-warp {
  position: absolute;
  bottom: 8px;
  margin-left: 16px;
}
</style>

<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    stateless
    permanent
    app
    class="background"
  >
    <v-container v-show="!mini" class="pa-0" style="height: 100px">
      <v-list nav justify="center">
        <v-list-item class="pa-14 pt-4">
          <v-img src="@/assets/LANGX.svg" contain />
        </v-list-item>
      </v-list>
    </v-container>
    <v-container v-show="mini" style="height: 100px">
      <v-list nav dense justify="center" class="mt-5 mx-n1 pa-0">
        <v-list-item list>
          <v-img src="@/assets/equ_square_white.png" contain />
        </v-list-item>
      </v-list>
    </v-container>
    <v-divider color="white" />
    <v-list-item v-show="mini">
      <v-list-item-icon>
        <v-icon dark> mdi-account-circle </v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item v-show="!mini">
      <v-list-item-title class="font-weight-regular white--text">
        {{ email }}
      </v-list-item-title>
      <v-list-item-icon>
        <v-icon dark @click.stop="mini = !mini"> mdi-chevron-left </v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-divider color="white" />
    <v-list nav dense>
      <div v-for="item in items" :key="item.title" class="white--text">
        <div v-if="item.sub">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="white--text">
                <v-list-item-title text--white>{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="subitem in item.sub"
              :key="subitem.title"
              :to="subitem.path"
              link
              class="white--text"
            >
              <v-list-item-content>
                <v-list-item-title text--white>{{
                  subitem.title
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="white">{{ subitem.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-else>
          <v-list-item list class="white--text" link :to="item.path">
            <v-list-item-icon>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </div>
      </div>
      <language-switching color="white">
        <template v-slot:activator="{ attrs, on }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
            class="white--text"
            style="position: absolute; bottom: 0; width: 100%"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('common.languageSwitching')
            }}</v-list-item-title>
          </v-list-item>
        </template>
      </language-switching>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import store from '@/store'
import { i18n } from '@/locales/i18n'
import LanguageSwitching from '@/components/Common/LanguageSwitchingComponent.vue'

export default defineComponent({
  name: 'SideBar',
  components: {
    LanguageSwitching,
  },
  data() {
    return {
      drawer: true,
      mini: false,
    }
  },
  setup() {
    // サイドバーにstore.state.emailを表示、存在しない場合はnot found（アクセス制御で回避しているので本来は発生しない）
    let email = 'err: not found'
    if (store.state.email) {
      email = store.state.email
    }

    type Item = {
      icon: string
      title: string
      path: string
      sub?: {
        icon: string
        title: string
        path: string
      }[]
    }
    const items: Item[] = [
      {
        icon: 'mdi-view-grid',
        title: 'home.navigationBar.home',
        path: '/dashboard/home',
      },
      {
        icon: 'mdi-package-variant-closed-plus',
        title: 'home.navigationBar.createPackage',
        path: '/dashboard/package/new',
      },
      {
        icon: 'mdi-pen-plus',
        title: 'home.navigationBar.campaignSetting',
        path: '/dashboard/exam/new',
      },
      {
        icon: 'mdi-card-account-details',
        title: 'home.navigationBar.searchTesterData',
        path: '/dashboard/learners',
      },
      {
        icon: 'mdi-shield-lock-outline',
        title: 'home.navigationBar.securitySetting',
        path: '/dashboard/security',
      },
      {
        icon: 'mdi-logout',
        title: 'home.navigationBar.signOut',
        path: '/dashboard/signout',
      },
      // 操作ガイドを下にする場合は以下のまとまりをコメントアウトしてください
      {
        icon: 'mdi-information-outline',
        title: 'home.navigationBar.operatingGuide',
        path: '/dashboard/guide',
      },
    ]
    const guide = {
      icon: 'mdi-information-outline',
      title: '操作ガイド',
      path: '/dashboard/guide',
    }

    const languageSwitchingItems = [
      { title: '日本語', value: 'ja' },
      { title: 'English', value: 'en' },
    ]

    const languageSwitching = (value: string) => {
      i18n.locale = value
    }

    return { items, guide, email, languageSwitchingItems, languageSwitching }
  },
})
</script>
