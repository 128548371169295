import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c(VCard,{staticClass:"mx-auto dialog-card dialog-card--large"},[_c(VCardTitle,{staticClass:"justify-center dialog-header"},[_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponErrorDialog.error'))+" ")]),_c(VCardText,[(_vm.isCouponConfirmed)?_c('p',{staticClass:"text-center mt-6",domProps:{"innerHTML":_vm._s(_vm.$t('viewCouponLists.couponErrorDialog.couponErrorMessage'))}}):_vm._e(),_c(VCard,{staticClass:"mx-3 dialog-card--medium"},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponErrorDialog.couponCode'))+_vm._s(_vm.coupon.couponCode)),_c('br'),_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponErrorDialog.password'))+_vm._s(_vm.coupon.password)+" ")])],1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"px-5 mb-4 mt-n1 dialog-btn dialog-btn--large",attrs:{"color":"#1b2643","dark":""},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('viewCouponLists.couponErrorDialog.close'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }