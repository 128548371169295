<style scoped>
.dialog-card--medium {
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}
</style>

<template>
  <v-card width="85%" class="mx-auto dialog-card--medium mt-8">
    <template v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
      <p class="w-full text-center primary--text text-caption">
        {{ $t('viewCouponLists.progress.loadingCouponCounts') }}
      </p>
    </template>
    <v-card-title>{{ code }}</v-card-title>
    <v-card-subtitle>
      {{ name }}
    </v-card-subtitle>
    <v-card-text>
      {{ $t('viewCouponLists.couponListDialog.totalCoupons') }}
      {{ totalCouponCount }}<br />
      {{ $t('viewCouponLists.couponListDialog.usedCoupons') }}
      {{ usedCouponCount }}<br />
      {{ $t('viewCouponLists.couponListDialog.unusedCoupons') }}
      {{ unusedCouponCount }}
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CouponStatisticsCard',

  props: {
    code: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    totalCouponCount: {
      type: Number,
      required: true,
    },
    usedCouponCount: {
      type: Number,
      required: true,
    },
    unusedCouponCount: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
