import { deleteDoc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '@/plugins/firebase'
import { doc } from 'firebase/firestore'

import { isCouponConfirmed } from './couponUtils'
import { createCouponModificationNotAllowedError } from './errorUtils'

/**
 * Deletes a coupon from a package/campaign and handles associated campaigns if deleting from package
 * @param collectionName - Name of collection ('packages' or 'campaigns')
 * @param id - ID of the document containing the coupon
 * @param couponCode - Code of the coupon to delete
 * @throws Error if deletion fails or if coupon is confirmed
 */
export async function deleteCoupon(
  collectionName: 'packages' | 'campaigns',
  id: string,
  couponCode: string
): Promise<void> {
  const couponRef = doc(db, collectionName, id, 'coupons', couponCode)
  const docRef = doc(db, collectionName, id)

  try {
    // Check if the coupon is confirmed
    const isConfirmed = await isCouponConfirmed(couponRef)
    if (isConfirmed) {
      throw createCouponModificationNotAllowedError(couponCode)
    }

    // Get current document to check existence and coupon count
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) {
      return
    }

    // Delete coupon document and update count atomically
    await Promise.all([
      deleteDoc(couponRef),
      updateDoc(docRef, {
        couponCount: Math.max(0, (docSnap.data().couponCount || 0) - 1),
      }),
    ])

    // If deleting from package, also delete from associated campaigns
    if (collectionName === 'packages') {
      const campaignIds = docSnap.data().campaignIds || []
      await Promise.all(
        campaignIds.map((campaignId: string) =>
          deleteCoupon('campaigns', campaignId, couponCode)
        )
      )
    }
  } catch (error) {
    console.error(
      `Failed to delete coupon ${couponCode} from ${collectionName}/${id}:`,
      error
    )
    throw error
  }
}
