import { getJstTimestamp } from '@/components/utils/dateUtils'
import { CouponListItem } from '@/resources/coupon'
import { downloadCsv, createCsvContent } from './csvUtils'

/**
 * Downloads coupon list as CSV file
 * @param coupons Array of coupon items to be exported
 * @returns {void}
 */
export function downloadCouponListAsCsv(coupons: CouponListItem[]) {
  const headers = [
    'CouponCode',
    'Password',
    'Ticket Registration',
    'User Display Name',
  ]
  const rows = coupons.map((coupon) => [
    coupon.couponCode,
    coupon.password,
    coupon.isConfirmed ? 'completed' : 'incomplete',
    coupon.displayName,
  ])

  const csvContent = createCsvContent(headers, rows)
  downloadCsv(csvContent, `couponList_${getJstTimestamp()}.csv`)
}

export function downloadNoResultCsv() {
  const content = 'No Results'
  const filename = `exportData_${getJstTimestamp()}.csv`
  downloadCsv(content, filename)
}
