<style scoped>
.dialog-card {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-card--large {
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.dialog-card--medium {
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}

.dialog-btn {
  background: white 0% 0% no-repeat padding-box;
}

.dialog-btn--large {
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.dialog-btn--medium {
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.dialog-header {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: white;
  font-size: 14px;
}

.dialog-header__text--active {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.dialog-table td {
  border-bottom: 1px solid #dbdbdb;
}

.dialog-input {
  font-size: 14px;
}

.dialog-table__column--display-name {
  min-width: 160px;
  max-width: 160px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <v-dialog v-model="dialogVisible" width="600px">
    <v-card class="mx-auto dialog-card dialog-card--large">
      <v-card-title class="justify-center dialog-header">
        {{ $t('viewCouponLists.couponErrorDialog.error') }}
      </v-card-title>
      <v-card-text>
        <p
          class="text-center mt-6"
          v-if="isCouponConfirmed"
          v-html="$t('viewCouponLists.couponErrorDialog.couponErrorMessage')"
        ></p>
        <v-card class="mx-3 dialog-card--medium">
          <v-card-text>
            {{ $t('viewCouponLists.couponErrorDialog.couponCode')
            }}{{ coupon.couponCode }}<br />
            {{ $t('viewCouponLists.couponErrorDialog.password')
            }}{{ coupon.password }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="#1b2643"
          class="px-5 mb-4 mt-n1 dialog-btn dialog-btn--large"
          dark
          @click="handleClose"
        >
          {{ $t('viewCouponLists.couponErrorDialog.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import { CouponListItem } from '@/resources/coupon'

export default Vue.extend({
  name: 'EditCouponErrorDialog',

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    coupon: {
      type: Object as () => CouponListItem,
      required: true,
    },
    isCouponConfirmed: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    dialogVisible: {
      get(): boolean {
        return this.isVisible
      },
      set(value: boolean) {
        this.$emit('update:isVisible', value)
      },
    },
  },

  methods: {
    handleClose() {
      this.dialogVisible = false
    },
  },
})
</script>
