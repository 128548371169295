<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: #ffffff;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
  font-size: 16px;
}

.bold-large-text {
  font-weight: bold;
  font-size: 18px;
}
</style>

<template>
  <v-card class="ma-10 card_shadow">
    <v-container fluid>
      <v-row class="headline">
        <v-col class="active_text">
          <p>{{ $t('packageResults.title') }}</p>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="error" class="d-flex justify-start pa-5">
      <p>{{ $t('packageResults.packageNotFound') }}</p>
    </div>
    <div v-else class="d-flex flex-column pa-5" style="gap: 40px">
      <div class="d-flex justify-end">
        <v-switch
          v-model="speakingTestOnly"
          :label="$t('packageResults.speakingTestOnly')"
        />
      </div>

      <div class="d-flex flex-column" style="gap: 20px">
        <span class="text-h6">{{ $t('packageResults.packageName') }}</span>
        {{ packageData?.name }} ({{ packageData?.code }})
      </div>

      <learner-population-data
        :data="learnerPopulationData"
        :speakingTestOnly="speakingTestOnly"
        :loading="loading"
      />

      <overview-by-category
        :speakingTestOnly="speakingTestOnly"
        :data="learnerPopulationData"
        :loading="loading"
      />
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import type { CampaignResults, Package } from '@/resources/package'
import { collection, getDocs, query, where } from 'firebase/firestore'
import LearnerPopulationData from '@/components/Dashboard/PackageResults/LearnerPopulationData.vue'
import OverviewByCategory from '@/components/Dashboard/PackageResults/OverviewByCategory.vue'
import { getCampaignResults } from '@/utils/getCampaignResults'
import store from '@/store'

type WithId<T> = T & { id: string }

export default defineComponent({
  name: 'PackageResults',

  components: {
    LearnerPopulationData,
    OverviewByCategory,
  },

  data() {
    return {
      speakingTestOnly: false,
      packageData: null as Package | null,
      campaignIds: [] as string[],
      learnerPopulationData: [] as CampaignResults[],
      loading: false,
      error: false,
    }
  },

  methods: {
    async getPackageByCode(
      packageCode: string
    ): Promise<WithId<Package> | null> {
      const packagesRef = collection(db, 'packages')
      const packageQuery = query(packagesRef, where('code', '==', packageCode))
      const querySnapshot = await getDocs(packageQuery)

      if (querySnapshot.empty) return null

      const doc = querySnapshot.docs[0]
      return {
        ...doc.data(),
        id: doc.id,
      } as WithId<Package>
    },

    async loadPackageData() {
      try {
        const customerId = store.state.customerId as unknown as string
        const packageCode = this.$route.params.packageCode

        this.packageData = await this.getPackageByCode(packageCode)

        if (
          this.packageData === null ||
          this.packageData.customerId !== customerId
        ) {
          throw new Error(
            'No package found and/or package not owned by the customer'
          )
        }

        if (this.packageData?.campaignIds.length) {
          this.campaignIds = this.packageData.campaignIds

          for (const campaignId of this.packageData.campaignIds) {
            const res = await getCampaignResults(customerId, '', campaignId)
            this.learnerPopulationData.push(res)
          }
        }
      } catch (error) {
        console.error('error:', error)
        this.error = true
      }
    },
  },

  async mounted() {
    this.loading = true
    await this.loadPackageData()
    this.loading = false
  },
})
</script>
